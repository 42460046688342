<template>
  <div>
    <div
      v-for="(fieldDefinition, index) in getTransformedFieldDefinitions(callFlowItem)"
      :key="index"
    >
      <div v-if="shouldUseDropdown(fieldDefinition.fieldType)">
        <inner-label-dropdown
          :clear-on-select="!canSelectMultiple(fieldDefinition.fieldType)"
          :close-on-select="!canSelectMultiple(fieldDefinition.fieldType)"
          :disabled="isDisabled(fieldDefinition)"
          :dropdownItems="fieldDefinition.options || []"
          :isMultiSelect="canSelectMultiple(fieldDefinition.fieldType)"
          :label="fieldDefinition.label"
          :placeholder="getFieldDefinitionLabel(fieldDefinition)"
          :value="getAnswersFieldValue(callFlowItem, fieldDefinition)"
          class="mb-2"
          dropdownLabel="label"
          track-by="value"
          @input="(selectedValue) => handleAnswersChanged(selectedValue, fieldDefinition, canSelectMultiple(fieldDefinition.fieldType))"
        />
      </div>
      <div v-else-if="shouldUseInput(fieldDefinition.fieldType)">
        <inner-label-input
          :disabled="isDisabled(fieldDefinition)"
          :isTextInputField="!isTextArea(fieldDefinition.fieldType)"
          :label="fieldDefinition.label"
          :placeholder="getFieldDefinitionLabel(fieldDefinition)"
          :value="getTextFieldValue(callFlowItem, fieldDefinition)"
          class="mb-2"
          @input="(selectedValue) => handleNoteFieldChanged(selectedValue, fieldDefinition)"
        />
      </div>
      <div v-else>
        <CrmField
          :disabled="isDisabled(fieldDefinition)"
          :field-definition="fieldDefinition"
          :is-open-from-call-wrap-up="true"
          :item="callFlowItem"
          @input="(selectedValue)=>handleInputAnswers(selectedValue, fieldDefinition)"
        ></CrmField>
      </div>
    </div>
  </div>
</template>

<script>
import InnerLabelInput from "@/apps/base/InnerLabelInput.vue"
import InnerLabelDropdown from "@/apps/base/InnerLabelDropdown.vue"
import CrmField from "@/apps/call/CallViewItem_components/CrmField"
import { capitalizeFirstLetter, caseInsensitiveLooseEqual } from "@/apps/talkscript/components/utils"
import CallItemMixin from "@/apps/call/CallItemMixin"

import { typeServices } from "@/apps/call"
import { mapGetters } from "vuex"

export default {
  name: "CallFlowCrmItem",
  mixins: [CallItemMixin],
  data () {
    return {
      selectedAnswers: this.getSelectedAnswers(this.callFlowItem),
      staticTextDefault: {
        enterLabel: "Enter",
        readOnly: "Read-only field - value cannot be changed",
        notSupported: "This field type is not supported"
      }
    }
  },
  components: {
    InnerLabelInput,
    InnerLabelDropdown,
    CrmField
  },
  props: {
    callFlowItem: {
      type: Object,
      default: () => {
      }
    },
    isCallSavedToCrm: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    ...typeServices,
    getTextFieldValue (item, fieldDefinition) {
      let selectedAnswer
      if (item.selectedAnswers) {
        selectedAnswer = item.selectedAnswers.filter(ans => ans && caseInsensitiveLooseEqual(ans.question_id, fieldDefinition.id))[0]
      } else selectedAnswer = this.hasAnswers(item) ? item.call_item.answers.find((answer) => caseInsensitiveLooseEqual(answer.question_id, fieldDefinition.id)) : null
      return selectedAnswer || null
    },
    getSingleSelectFieldValue (item, fieldDefinition) {
      let selectedAnswer
      if (item.selectedAnswers) {
        selectedAnswer = item.selectedAnswers.filter(ans => ans && caseInsensitiveLooseEqual(ans.question_id, fieldDefinition.id))[0] || null
      } else selectedAnswer = this.hasAnswers(item) ? item.call_item.answers.find((answer) => caseInsensitiveLooseEqual(answer.question_id, fieldDefinition.id)) : null

      if (selectedAnswer) {
        const answerOptions = []
        fieldDefinition.options.forEach(option => {
          if (option.value.toString() === selectedAnswer.text.toString()) {
            option.isAIDetected = !!selectedAnswer.isAIDetected
            answerOptions.push(option)
          }
        })
        return answerOptions
      } else return null
    },
    getMultiSelectFieldValue (item, fieldDefinition) {
      let selectedAnswers
      if (item.selectedAnswers) {
        selectedAnswers = item.selectedAnswers.filter(ans => ans && caseInsensitiveLooseEqual(ans.question_id, fieldDefinition.id))
      } else {
        selectedAnswers = this.hasAnswers(item)
          ? item.call_item.answers.filter((answer) => caseInsensitiveLooseEqual(answer.question_id, fieldDefinition.id))
          : []
      }

      if (selectedAnswers) {
        const answerOptions = []
        fieldDefinition.options.forEach(option => {
          if (selectedAnswers.some(answer => caseInsensitiveLooseEqual(answer.text, option.value))) {
            const selectedAnswer = selectedAnswers.find(ans => caseInsensitiveLooseEqual(ans.text, option.value))
            if (selectedAnswer) {
              option.isAIDetected = !!selectedAnswer.isAIDetected
            }
            answerOptions.push(option)
          }
        })
        return answerOptions
      } else return null
    },
    getAnswersFieldValue (item, fieldDefinition) {
      return this.canSelectMultiple(fieldDefinition.fieldType)
        ? this.getMultiSelectFieldValue(item, fieldDefinition)
        : this.getSingleSelectFieldValue(item, fieldDefinition)
    },
    getFieldDefinitionLabel (fieldDefinition) {
      if (fieldDefinition.readonly) return this.staticText.readOnly
      else if (!fieldDefinition.supported) return this.staticText.notSupported
      else {
        const enterLabel = this.staticText.enterLabel
        const fieldLabel = fieldDefinition.label.toLowerCase()
        return this.user.language !== "de" ? `${enterLabel} ${fieldLabel}` : `${capitalizeFirstLetter(fieldLabel)} ${enterLabel}`
      }
    },
    getTransformedFieldDefinitions (callFlowItem) {
      return this.getFieldDefinitions(callFlowItem).map(fieldDefinition => {
        return this.transformFieldDefinition(fieldDefinition)
      })
    },
    transformFieldDefinition (fieldDefinition) {
      return { ...fieldDefinition, question_id: fieldDefinition.id, value_type: fieldDefinition.type }
    },
    handleInputAnswers (answers, fieldDefinition) {
      const answer = answers ? { ...answers[0], question_id: fieldDefinition.id } : null
      this.handleSingleValueItemChanged(answer, fieldDefinition, true)
    },
    handleAnswersChanged (answers, fieldDefinition, isMultiSelect) {
      if (!isMultiSelect) {
        const answer = answers ? answers.value : null
        this.handleSingleValueItemChanged(answer, fieldDefinition)
      } else this.handleMultiValueItemChanged(answers, fieldDefinition)
    },
    async handleMultiValueItemChanged (answers, fieldDefinition) {
      this.selectedAnswers = this.selectedAnswers.filter(answer => !caseInsensitiveLooseEqual(answer.question_id, fieldDefinition.question_id))

      if (!!answers && !!answers.length) {
        answers.forEach((answer) => {
          const transformedAnswer = {
            value_type: fieldDefinition.value_type,
            question_id: fieldDefinition.question_id,
            text: answer.value
          }
          this.selectedAnswers.push(transformedAnswer)
        })
      }
      this.$emit("item-changed", this.selectedAnswers)
    },
    handleNoteFieldChanged (selectedValue, fieldDefinition) {
      selectedValue = selectedValue || null
      this.handleSingleValueItemChanged(selectedValue, fieldDefinition)
    },
    handleSingleValueItemChanged (answer, fieldDefinition, isAnswerFormatted = false) {
      const answerIndex = this.selectedAnswers.findIndex(answer => caseInsensitiveLooseEqual(answer.question_id, fieldDefinition.question_id))
      if (!answer) this.selectedAnswers.splice(answerIndex, 1)
      else if (answerIndex === -1) {
        const newAnswer = !isAnswerFormatted
          ? {
              value_type: fieldDefinition.value_type,
              question_id: fieldDefinition.question_id,
              text: answer
            }
          : answer
        this.selectedAnswers.push(newAnswer)
      } else this.selectedAnswers[answerIndex].text = !isAnswerFormatted ? answer : answer.text

      this.$emit("item-changed", this.selectedAnswers)
    },
    isDisabled (fieldDefinition) {
      return this.isCallSavedToCrm || fieldDefinition.readonly || !fieldDefinition.supported
    },
    getFieldDefinitionOptions (fieldDefinition) {
      return fieldDefinition.options.map(option => {
        return { ...option, text: option.value }
      })
    },
    getSelectedAnswers (item) {
      return this.hasAnswers(item) ? item.call_item.answers : []
    },
    shouldUseDropdown (type) {
      return this.isSelect(type) || this.isRadio(type)
    },
    shouldUseInput (type) {
      return this.isText(type) || this.isNumber(type) || this.isTextArea(type)
    }
  }
}
</script>

<style>

</style>
