<template>
  <div :class="getCrmFieldClass">
    <div class="label">{{ computedFieldDefinitionLabel }}</div>

    <typed-input-field
      id="typed-input-field-202103212027"
      class="input-field"
      :value="internalValue"
      :value-object="internalValueObject"
      :item-id="itemUniqueId"
      :linked-field="item.linked_field"
      :field-definition="fieldDefinition"
      :is-open-from-call-wrap-up="isOpenFromCallWrapUp"
      :isDisabled="disabled"
      @input="handleValueChanged"
    ></typed-input-field>

  </div>
</template>

<script>
import { typeServices, utils } from "@/apps/call"
import _isEqual from "lodash/isEqual"
import TypedInputField from "@/apps/call/CallViewItem_components/TypedInputField"
import { mapGetters } from "vuex"
import { currentCallStore } from "@/store/services/callStore"
import { caseInsensitiveLooseEqual } from "@/apps/talkscript/components/utils"

export default {
  name: "CrmField",
  components: {
    TypedInputField
  },
  props: {
    fieldDefinition: {
      type: Object,
      required: true
    },
    item: { // callItem
      type: Object,
      required: true
    },
    itemId: {
      type: [String, Number],
      default: "",
      required: false
    },
    clearCrmDataCount: {
      type: Number,
      default: 0
    },
    downloadCrmDataCount: {
      type: Number,
      default: 0
    },
    isOpenFromCallWrapUp: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      utils,
      internalValue: null,
      internalValueObject: null, // contains information about internal value
      previousValueEmitted: [],
      crmUpdatedFromCounterpartData: false
    }
  },
  computed: {
    ...mapGetters({
      counterpartsWithLoadedData: "counterpartStore/getCounterpartsWithLoadedData"
    }),
    itemUniqueId (item) {
      return this.itemId ? this.itemId : item.uniqueId
    },
    computedFieldDefinitionLabel () {
      const fieldDefinitionLabel = this.fieldDefinition.label
      return this.isSingleSelectBox(this.fieldDefinition.fieldType) ? `${fieldDefinitionLabel}` : `${fieldDefinitionLabel}:`
    },
    crmObject () {
      return this.item.linked_field.crm_object_link
    },
    answers () {
      let answers
      if (this.item.selectedAnswers) {
        answers = this.item.selectedAnswers.length ? this.item.selectedAnswers : []
      } else {
        answers = this.item.call_item ? this.item.call_item.answers : null
      }

      if (answers && answers.length) {
        return answers.filter(answer => caseInsensitiveLooseEqual(answer.question_id, this.fieldDefinition.id))
      }
      return answers
    },
    isConversationEdited () {
      return !!this.$route.query.editConversation
    },
    showInnerLabel () {
      return this.isOpenFromCallWrapUp && !this.isSingleSelectBox(this.fieldDefinition.fieldType)
    },
    getCrmFieldClass () {
      let defaultClass = "crm-field"
      if (this.isSingleSelectBox(this.fieldDefinition.fieldType)) defaultClass += " checkbox-field"
      if (this.showInnerLabel) defaultClass += " inner-label"
      return defaultClass
    }
  },
  watch: {
    answers: {
      handler (val) {
        if (val) {
          this.handleExistingValues()
        }
      },
      immediate: true,
      deep: true
    },
    clearCrmDataCount (val) {
      if (val && this.crmUpdatedFromCounterpartData) {
        this.internalValue = null
        this.$emit("input", this.getTransformedValue(this.internalValue))
        this.crmUpdatedFromCounterpartData = false
      }
    },
    downloadCrmDataCount (val) {
      if (val) this.updateCrmField()
    }
  },
  mounted () {
    if (this.downloadCrmDataCount > 0 && !this.isConversationEdited) this.updateCrmField()
  },
  methods: {
    ...typeServices,
    handleValueChanged (newValue) {
      const transformedValue = this.getTransformedValue(newValue)
      if (this.isOpenFromCallWrapUp && !newValue) this.$emit("input", newValue)

      if (!_isEqual(this.previousValueEmitted, transformedValue)) {
        this.$emit("input", transformedValue)
        if (!this.isOpenFromCallWrapUp) {
          this.handleCrmFieldLoaded({
            crmObject: this.crmObject,
            value: transformedValue,
            itemId: this.item.id
          })
        }
        this.previousValueEmitted = transformedValue
      }
    },
    handleCrmFieldLoaded (crmObject) {
      currentCallStore.handleCrmFieldLoaded(crmObject)
    },
    getTransformedValue (value) {
      // This function transforms the value to a form that is appropriate to save
      // this value as answer field in the item
      let result = []
      if (Array.isArray(value)) {
        result = value.map(item => {
          return {
            text: !!item && item.hasOwnProperty("value") ? item.value : item,
            value_type: this.fieldDefinition.type
          }
        })
      } else if (this.isSelect(this.fieldDefinition.fieldType) && !!value && !!value.value) {
        result = [{ text: value.value }]
      } else if (this.isObject(value)) {
        result = [{ text: JSON.stringify(value), value_type: "object" }]
      } else if (value !== null && value !== undefined) {
        // if value is false or anything else then, convert it to a string when adding to array
        result = [{
          text: value.toString(),
          value_type: this.fieldDefinition.type
        }]
      }
      return result.filter(item => !!item.text) // remove answers with empty text or null value
    },
    getValueForSalesforce (counterpart, fieldDefinition) {
      let value = counterpart.external_resource[fieldDefinition.id]
      if (value === null || value === undefined) return null
      if (this.isSelect(this.fieldDefinition.fieldType)) {
        value = value.split(";").map(item => {
          return { label: item, value: item }
        })
      }
      return value
    },
    getValueForHubSpot (counterpart, fieldDefinition) {
      const field = counterpart.external_resource.properties[fieldDefinition.id]
      if (!field || !field.value) return null
      let value = field.value
      if (this.isDate(this.fieldDefinition.fieldType)) {
        value = this.utils.convertFromUnixFormat(value, "YYYY-MM-DD")
      }
      if (this.isDateTime(this.fieldDefinition.fieldType)) {
        value = this.utils.convertFromUnixFormat(value, "YYYY-MM-DD hh:mm")
      }
      if (this.isSelect(this.fieldDefinition.fieldType)) {
        value = value.split(";").map(item => {
          return { label: item, value: item }
        })
      }
      return value
    },
    getValueForPipedrive (counterpart, fieldDefinition) {
      let value = counterpart.external_resource[fieldDefinition.description]
      if (!value) return null
      if (this.isSelect(this.fieldDefinition.fieldType)) {
        value = value.split(",").map(item => {
          return { label: item, value: item }
        })
      }

      // phone and email have multiple registries. Load the primary value only
      if (this.fieldDefinition.description === "phone" || this.fieldDefinition.description === "email") {
        return value.find(entry => entry.primary).value
      }
      return value
    },
    getValueForDynamics365 (counterpart, fieldDefinition) {
      const logicalName = fieldDefinition.logical_name
      const fieldType = fieldDefinition.fieldType

      if (this.isLookup(fieldType)) {
        return counterpart.external_resource[`_${logicalName}_value`]
      }

      const value = counterpart.external_resource[logicalName]

      if (this.isSelect(fieldType)) {
        if (fieldType === "checkbox") {
          const valueList = value.split(",")
          return valueList.map(item => {
            return fieldDefinition.options.find(option => option.value.toString() === item.toString())
          })
        } else {
          return fieldDefinition.options.find(option => option.value === value)
        }
      }

      return value
    },
    getValueForZendesk (counterpart, fieldDefinition) {
      const counterpartData = counterpart.external_resource.data
      const crmField = fieldDefinition.label
      const isCustomField = fieldDefinition.is_custom_field
      return isCustomField ? counterpartData.custom_fields[crmField] : counterpartData[crmField]
    },
    setCRMValue (counterpart) {
      try {
        const mapping = {
          salesforce: this.getValueForSalesforce,
          hubspot: this.getValueForHubSpot,
          pipedrive: this.getValueForPipedrive,
          dynamics365: this.getValueForDynamics365,
          zendesk: this.getValueForZendesk
        }
        const getValueMethod = mapping[counterpart.crm_service]
        if (getValueMethod) {
          this.internalValue = getValueMethod(counterpart, this.fieldDefinition)
          this.$emit("input", this.getTransformedValue(this.internalValue))
        }
        this.crmUpdatedFromCounterpartData = true
      } catch (err) {
        console.error(err)
      }
    },
    isObject (value) {
      return typeof value === "object" && !!value && Object.values(value).length > 0
    },
    getAnswersForSelectItemType (answers) {
      // Retrieve answers(in label-value format) that are available in the options list
      return this.fieldDefinition.options.filter(option =>
        answers.some(answer => option.value.toString() === answer.text.toString())
      )
    },
    handleExistingValues () {
      try {
        if (!this.answers || this.answers.length < 1) {
          this.internalValue = null
          return
        }
        // if previously selected answers exists for the item retrieve them accordingly
        if (this.isSelect(this.fieldDefinition.fieldType)) {
          this.internalValue = this.getAnswersForSelectItemType(this.answers)
        } else if (this.isTag(this.fieldDefinition.fieldType)) {
          this.internalValue = this.answers.map(item => item.text)
        } else if (this.answers[0].value_type === "object") {
          // When the crm field data is supposed to be an object but the answer is
          // saved in text format we must convert the text to object
          this.internalValue = JSON.parse(this.answers[0].text)
        } else {
          this.internalValue = this.answers[0].text
          // for boolean type and datetime type answer, we need other attributes of answers as well
          this.internalValueObject = this.answers[0]
        }
        this.$emit("set-internal-value", this.getTransformedValue(this.internalValue))
      } catch (error) {
        console.error(error)
      }
    },
    updateCrmField () {
      const counterpartCRM = this.crmObject.service.key
      const counterpartType = this.crmObject.object_type
      const crmServiceAndTypeCombination = `${counterpartCRM}-${counterpartType}`
      const counterpart = this.counterpartsWithLoadedData[crmServiceAndTypeCombination]

      if (!!counterpart && !!counterpart.external_resource && counterpart.crmDataLoaded === true && !!this.fieldDefinition) {
        this.setCRMValue(counterpart)
      }
    }
  }
}
</script>

<style scoped lang="scss">

.crm-field {
  margin: 8px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  .label {
    width: calc(100% / 3);
    word-break: break-word;
  }

  .input-field {
    flex-grow: 1;
    width: calc(200% / 3);
  }

  &.inner-label {
    .label {
      position: absolute;
      top: 8px;
      left: 16px;
      z-index: 3;
      font-size: 12px;
      line-height: 14px;
      width: unset;
      color: rgba(11, 17, 93, 0.6);
    }

    .input-field {
      width: 100%;
    }
  }

  &.checkbox-field {
    flex-direction: row-reverse;
    justify-content: flex-end;

    .label {
      width: unset;
    }

    .input-field {
      width: unset;
      flex-grow: unset;
    }
  }
}

</style>
